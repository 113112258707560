import { Link } from 'react-router-dom';
import QRCode from "react-qr-code";
import Footer from "./Footer";
import { useTranslation } from 'react-i18next';

// import emergency from './img/emergency.png';
// import tags from './img/tags.png';
// import scan from './img/scan.png';
import sec from './img/sec.png';

const NIP = "7930"
const OTP = "/b7b0d4ea472eb4a34cce335d1963c07f898630895bee611f605f671b61e308c0"

function textInfo(t) {
  return (
    <div className="container" style={{ "overflow-y": "auto" }}>
      <div className=" text-center justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom ">
        <h1 className="h2 text-dark"> {t('home.landing.s1title')} </h1>
        <em class="text-secondary"> {t('home.landing.s1l1txt')}</em>
      </div>
      <br />
      <br />

      <div class="container">
        <div class="row">
          <div class="col-sm  text-left">
            <em>
              {t('home.landing.s1quote0')}
            </em>
            <br />
            <br />
            <Link className="nav-link active text-center" to={process.env.REACT_APP_SIGNUP} target="_blank">
              <button className="btn btn-outline-dark my-2">{t('home.landing.s1btn2')}</button>
            </Link>
            {/* <em> {t('home.landing.s1quote0')} </em> */}
          </div>

          <div class="col-sm text-start" >
            <br />
            <strong>
              {t('home.landing.s1caption')}
            </strong>
            <br />
            <br />
          </div>
        </div>
      </div>

      <br />
      <hr className="my-4" />
      <br />
      <div class="container">
        <div class="row">
          <div class="col-sm  text-left">
            <h3 className="text-start text-dark">{t('home.landing.s11title')}</h3>
            <em>{t('home.landing.s11desc')}</em>
            <br />
            <br />
            <div class="col-sm">
              <ul>
                <li><strong class="text-dark-emphasis">{t('home.landing.s11l1txt')}</strong><br />{t('home.landing.s11l1desc')}</li>
                <li><strong class="text-dark-emphasis">{t('home.landing.s12l1txt')}</strong><br />{t('home.landing.s12l1desc')}</li>
                <li><strong class="text-dark-emphasis">{t('home.landing.s13l1txt')}</strong><br />{t('home.landing.s13l1desc')}</li>
                <li><strong class="text-dark-emphasis">{t('home.landing.s14l1txt')}</strong><br />{t('home.landing.s14l1desc')}</li>
                <br />
              </ul>
            </div>
          </div>



          <div class="col-sm text-start" >
            <br />
            <div class="col-sm">
              <ul>
                <li><strong class="text-dark-emphasis">{t('home.landing.s15l1txt')}</strong><br />{t('home.landing.s15l1desc')}</li>
                <li><strong class="text-dark-emphasis">{t('home.landing.s16l1txt')}</strong><br />{t('home.landing.s16l1desc')}</li>
                <li><strong class="text-dark-emphasis">{t('home.landing.s17l1txt')}</strong><br />{t('home.landing.s17l1desc')}</li>
                <li><strong class="text-dark-emphasis">{t('home.landing.s18l1txt')}</strong><br />{t('home.landing.s18l1desc')}</li>
                <li><strong class="text-dark-emphasis">{t('home.landing.s19l1txt')}</strong><br />{t('home.landing.s19l1desc')}</li>
                <br />
              </ul>
            </div>
            <br />
            <br />
          </div>
        </div>
      </div>

      <br />
      <hr className="my-4" />
      <br />
      <div class="container">
        <h3 className="text-start text-dark">{t('home.landing.s2title')}</h3>
        <br />

        <div class="row">
          <div class="col-sm">
            <ul>
              <li><strong>{t('home.landing.s2l1desc')}</strong><br />{t('home.landing.s2l1txt')}</li>
              <li><strong>{t('home.landing.s2l2desc')}</strong><br />{t('home.landing.s2l2txt')}</li>
              <li><strong>{t('home.landing.s2l3desc')}</strong><br />{t('home.landing.s2l3txt')}</li>
              <br />
            </ul>
          </div>
          <div class="col-sm">


            <figure class="text-primary">
              <blockquote class="blockquote">
                <p class="mb-0">{t('home.landing.s2l4quote')}</p>

              </blockquote>
              <figcaption class="blockquote-footer">
                {t('home.landing.s2l4caption')}
              </figcaption>
            </figure>

          </div>
        </div>

        <div className="row">
          <div className="col-sm d-flex flex-column align-items-center">
            <img src={t('home.landing.s2img1')} alt="Emergency" className="img-thumbnail img-fluid" style={{ width: "500px" }} />
            <br />
            <br />
            <Link className="nav-link active" to={process.env.REACT_APP_SIGNUP} target="_blank">
              <button className="btn btn-outline-dark my-2">{t('home.landing.s1btn2')}</button>
            </Link>
          </div>

          <div className="col-sm d-flex flex-column align-items-center">
            <img src={t('home.landing.s2img2')} alt="Emergency" className="img-thumbnail img-fluid" style={{ width: "500px" }} />
            <br />
            <br />
            <Link className="nav-link active" to={"/emergency" + OTP}>
              <button type="button" className="btn btn-outline-secondary my-2">{t('home.landing.s1btn1')}</button>
            </Link>
          </div>
        </div>
      </div>


      <br />
      <hr className="my-4" />
      <br />
      <div class="container">
        <h3 className="text-start text-dark">{t('home.landing.s3title')}</h3>
        <br />
        <div class="row">

          <div class="col-sm">
            <ul>
              <li><strong>{t('home.landing.s3l1desc')}</strong>{t('home.landing.s3l1txt')}</li>
              <li><strong>{t('home.landing.s3l2desc')}</strong>{t('home.landing.s3l2txt')}</li>
              <li><strong>{t('home.landing.s3l3desc')}</strong>{t('home.landing.s3l3txt')}</li>
            </ul>
          </div>

          <div class="col-sm">
            <img src={sec} alt="Emergency" class="img-thumbnail img-fluid mx-auto" style={{ "width": "500px" }} />
          </div>

        </div>
      </div>

      <br />


      <hr className="my-4" />
      <br />
      <div class="container">
        <div class="row">

          <div class="col-sm" style={{ "max-width": "40rem" }}>
            <h3 className="text-start text-dark" >{t('home.landing.s6title')}</h3>
            <p>
              <p />
              <ul>
                <li><strong>{t('home.landing.s6l1txt')}</strong><br /> {t('home.landing.s6l1desc')}</li>
                <li><strong>{t('home.landing.s6l2txt')}</strong><br /> {t('home.landing.s6l2desc')}</li>
                <li><strong>{t('home.landing.s6l3txt')}</strong><br /> {t('home.landing.s6l3desc')}</li>
                <li><strong>{t('home.landing.s6l4txt')}</strong><br /> {t('home.landing.s6l4desc')}</li>
              </ul>
            </p>
          </div>

          <div class="col-sm mx-auto" style={{ "max-width": "30rem" }}>
            <br />
            <br />
            <em >
              {t('home.landing.s6quote')}
            </em>
          </div>

        </div>
      </div>
      <br />

      <hr className="my-4" />
      <br />
      <div className="text-center" style={{ "max-width": "30rem", "margin": "auto" }}>
        <h3>{t('home.landing.s7title')}</h3>
        <p>
          {t('home.landing.s7desc')}
        </p>
        <a href="mailto:contact@3vidence.com" class="btn btn-primary mt-3">{t('home.landing.s7btn')}</a>
      </div>
      <br />


    </div>
  )
}

function Home() {
  const { t } = useTranslation();

  return (
    <div >

      <main>
        <div class=" collapse d-flex flex-column flex-shrink-0 p-3 bg-light" style={{ minWidth: "120px", maxWidth: "20%", borderRight: "2px solid #ddd"  }}>
          <a href="/" class="d-flex align-items-center mb-3 mb-md-0 me-md-auto link-dark text-decoration-none">
          </a>
          <ul class="nav flex-column mb-auto">
            <li class="nav-item">
              <Link class="nav-link active" to="/home/overview" >
                {t('barside.overview')}
              </Link >
            </li>
            <li class="nav-item">
              <Link class="nav-link active" to="/home/about">
                {t('barside.about')}
              </Link >
            </li>
            <li class="nav-item">
              <Link class="nav-link active" to="/home/faq"> {t('barside.faq')}</Link >
            </li>
          </ul>
        </div>
        {textInfo(t)}
      </main>
      <Footer />
    </div>
  );
}

export default Home;
