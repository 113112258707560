import { Link } from 'react-router-dom';
import Footer from "./Footer";
import { useTranslation } from 'react-i18next';

import overview from './img/overview.png';


function textInfo(t) {
  return (
    <div className="container" style={{"overflow-y": "auto"}}>
      <div className="text-center justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
        <h1 className="h2"> {t('home.overview.s1title')}</h1>
      </div>

      <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
        <img src={overview} alt="Emergency" class="img-thumbnail img-fluid" style={{ "width": "250px" }} />
      </div>

      <br />

      <h3 className="centertxt">{t('home.overview.s2title')}</h3>
      <p>
        {t('home.overview.s2desc')}
      </p>
      <br />

      <h3 className="centertxt">{t('home.overview.s3title')}</h3>
      <ul>
        <li><strong>{t('home.overview.s3l1text')}</strong> {t('home.overview.s3l1desc')}</li>
        <li><strong>{t('home.overview.s3l2text')}</strong> {t('home.overview.s3l2desc')}</li>
        <li><strong>{t('home.overview.s3l3text')}</strong> {t('home.overview.s3l3desc')}</li>
      </ul>
      <br />

      <h3 className="centertxt">{t('home.overview.s4title')}</h3>
      <ul>
        <li><strong>{t('home.overview.s4l1text')}</strong> {t('home.overview.s4l1desc')}</li>
        <li><strong>{t('home.overview.s4l2text')}</strong> {t('home.overview.s4l2desc')}</li>
        <li><strong>{t('home.overview.s4l3text')}</strong> {t('home.overview.s4l3desc')}</li>
        <li><strong>{t('home.overview.s4l4text')}</strong> {t('home.overview.s4l4desc')}</li>
      </ul>
      <br />

      <h3 className="centertxt">{t('home.overview.s5title')}</h3>
      <p>
        {t('home.overview.s5desc1')}
      </p>
      <p>
        {t('home.overview.s5desc2')}
      </p>
      <br />
    </div>

  )
}



function Overview() {
  const { t } = useTranslation();

  return (
    <div >

      <main>
        <div class="d-flex flex-column flex-shrink-0 p-3 bg-light" style={{ minWidth: "120px", maxWidth: "20%", borderRight: "2px solid #ddd"  }}>
          <a href="/" class="d-flex align-items-center mb-3 mb-md-0 me-md-auto link-dark text-decoration-none">
          </a>
          <ul class="nav flex-column mb-auto">
            <li class="nav-item">
              <Link class="nav-link active" to="/home/overview" >
                {t('barside.overview')}
              </Link >
            </li>
            <li class="nav-item">
              <Link class="nav-link active" to="/home/about">
                {t('barside.about')}
              </Link >
            </li>
            <li class="nav-item">
              <Link class="nav-link active" to="/home/faq"> {t('barside.faq')}</Link >
            </li>
          </ul>
        </div>
        {textInfo(t)}
      </main>

      <Footer />
    </div>
  );
}

export default Overview;
