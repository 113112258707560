import Login from "./Login"
import { useToken, useOtp } from './UseToken'
import Footer from "./Footer";
import QRCode from "react-qr-code";
import { Link } from 'react-router-dom';

import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';

function Dashboard() {
  const { t } = useTranslation();
  const { token, setToken } = useToken()

  if (!token) {
    return <Login setToken={setToken} />
  }

  return (
    <div >

      <main>
        <div class="d-flex flex-column flex-shrink-0 p-3 bg-light" style={{ minWidth: "120px", maxWidth: "20%", borderRight: "2px solid #ddd" }}>
          <Link to="/" class="d-flex align-items-center mb-3 mb-md-0 me-md-auto link-dark text-decoration-none">
          </Link>
          <ul class="nav flex-column mb-auto">
            <li class="nav-item">
              <Link to="/app/dashboard" class="nav-link active" aria-current="page">
                {t('records.barside.dashboard')}
              </Link>
            </li>
            <li class="nav-item">
              <Link to="/app/create" class="nav-link active" aria-current="page">
                {t('records.barside.create')}
              </Link>
            </li>
            <li class="nav-item">
              <Link to="/app/records" class="nav-link active" aria-current="page">
                {t('records.barside.records')}
              </Link>
            </li>
            <li class="nav-item">
              <Link to="/app/delete" class="nav-link active" aria-current="page">
                {t('records.barside.delete')}
              </Link>
            </li>
            <li class="nav-item">
              <Link to="/app/delete/account" class="nav-link active" aria-current="page">
                {t('records.barside.deleteaccount')}
              </Link>
            </li>
          </ul>
        </div>

        <div className="container" style={{ "overflow-y": "auto" }}>
          <div className=" text-center justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom ">
            <h1 className="h2">{t('records.dashboard.s1title')}</h1>
          </div>


          <ul class="nav nav-tabs" role="tablist">
            <li class="nav-item" role="presentation">
              <a class="nav-link active" data-bs-toggle="tab" href="#notes" aria-selected="true" role="tab">{t('records.dashboard.s1title01')}</a>
            </li>
            <li class="nav-item" role="presentation">
              <a class="nav-link" data-bs-toggle="tab" href="#tips" aria-selected="false" role="tab" tabindex="-1">{t('records.dashboard.s1title02')}</a>
            </li>
          </ul>

          <div id="myTabContent" class="tab-content">
            <div class="tab-pane fade show active" id="notes" role="tabpanel">

              <br />
              <div class="container">
                <div class="row">
                  <div class="col-sm">
                    <div class="card border-danger mb-3" style={{ "max-width2": "20rem;" }}>
                      <div class="card-header">{t('records.dashboard.s1col1title')}</div>
                      <div class="card-body">
                        <h4 class="card-title text-dark">{t('records.dashboard.s1col1header')}</h4>
                        <p class="card-text">
                          {t('records.dashboard.s1col1txt')}
                        </p>
                        <ul>
                          <li>{t('records.dashboard.s1col1l1')}</li>
                          <li>{t('records.dashboard.s1col1l2')}</li>
                          <li>{t('records.dashboard.s1col1l3')}</li>
                          <li>{t('records.dashboard.s1col1l4')}</li>
                        </ul>
                      </div>
                    </div>
                  </div>

                  <div class="col-sm">
                    <div class="card border-success mb-3" style={{ "max-width2": "20rem;" }}>
                      <div class="card-header">{t('records.dashboard.s1col2title')}</div>
                      <div class="card-body">
                        <h4 class="card-title text-dark">{t('records.dashboard.s1col2header')}</h4>
                        <p class="card-text">
                          {t('records.dashboard.s1col2desc')}.<br />
                          <br />
                        </p>
                        <em>
                          <p>{t('records.dashboard.s1col2foot1')}<br />{t('records.dashboard.s1col2foot2')}</p>
                        </em>
                      </div>
                    </div>
                  </div>

                </div>
              </div>

            </div>

            <div class="tab-pane fade" id="tips" role="tabpanel">
              <br />
              <div class="container">
                <div class="row">
                  <div class="col-sm">
                    <div class="card border-success mb-3" style={{ "max-width2": "20rem;" }}>
                      <div class="card-header">{t('records.dashboard.s2col1title')}</div>
                      <div class="card-body">
                        <h4 class="card-title text-dark">{t('records.dashboard.s2col1header')}</h4>
                        <p class="card-text">
                          {t('records.dashboard.s2col1desc')}.<br />
                        </p>
                        <ul>
                          <li>{t('records.dashboard.s2col1l1')}</li>
                          <li>{t('records.dashboard.s2col1l2')}</li>
                          <li>{t('records.dashboard.s2col1l3')}</li>
                          <li>{t('records.dashboard.s2col1l4')}</li>
                          <li>{t('records.dashboard.s2col1l5')}</li>
                        </ul>
                        <em>
                          <p>{t('records.dashboard.s1col2foot1')}<br />{t('records.dashboard.s1col2foot2')}</p>
                        </em>
                      </div>
                    </div>
                  </div>

                  <div class="col-sm">

                  </div>

                </div>
              </div>

            </div>
          </div>





        </div>

      </main>

      <Footer />
    </div>
  );
}

export default Dashboard;
