import React, { useState } from "react";
import { useEffect } from "react";
import PropTypes from 'prop-types';
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

async function loginUser(credentials,turnstileToken) {
  return fetch(process.env.REACT_APP_BACKEND + '/auth/login', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json', 
      'cf-turnstile-response': turnstileToken 
    },
    body: JSON.stringify(credentials)
  })
    .then(data => data.json())
    .catch((error) => {
      console.log(error)
    })
}

export default function Login({ setToken }) {
  const [email, setUserName] = useState()
  const [password, setPassword] = useState()
  const [isFetching, setIsFetching] = useState(false);
  const [turnstileToken, setTurnstileToken] = useState('');

  useEffect(() => {
    const handleTurnstileCallback = () => {
      if (window.turnstile) {
        window.turnstile.render('.cf-turnstile', {
          sitekey: process.env.REACT_APP_SITEKEY,
          theme: "light",
          callback: (token) => setTurnstileToken(token)
        });
      }
    };

    if (typeof window.turnstile === 'undefined') {
      const script = document.createElement("script");
      script.src = "https://challenges.cloudflare.com/turnstile/v0/api.js";
      script.async = true;
      script.onload = handleTurnstileCallback;
      document.body.appendChild(script);
    } else {
      handleTurnstileCallback();
    }
  }, []);

  const handleSubmit = async e => {
    e.preventDefault();
    setIsFetching(true);

    if (!turnstileToken) {
      toast.error('Please complete the captcha');
      setIsFetching(false);
      return;
    }

    try {
      const response = await fetch(process.env.REACT_APP_BACKEND + '/auth/login', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'cf-turnstile-response': turnstileToken 
        },
        body: JSON.stringify({ email, password })
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw errorData.info;
      }
      const data = await response.json();
  
      setToken(data.token);
      sessionStorage.setItem('email', email);
      toast.success('Login Success');
    }
    catch (error) {
      toast.error('Bad User or Password!', {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      console.error('There was a problem with your fetch operation:', error);
      setTurnstileToken('');
      if (window.turnstile) {
        window.turnstile.reset();
      }
      // setTimeout(() => {
      //   window.location.reload();
      // }, 1000);
    } finally {
      setIsFetching(false);
    }
  }

  const { t } = useTranslation();

  return (
    <div>
      <div className="container ">
        <div className="row">
          <div className="col-3"></div>
          <div className="col">
            <br />
            <h1 className="h2">{t('records.login.s1title')}</h1>
            <form onSubmit={handleSubmit}>
              <label htmlFor="inputEmail" className="sr-only">{t('records.login.s1btn1txt')}</label>
              <input type="email" id="inputEmail" className="form-control" placeholder={t('records.login.s1btn1desc')} required onChange={e => setUserName(e.target.value)}></input>
              <label htmlFor="inputPassword" className="sr-only">{t('records.login.s1btn2txt')}</label>
              <input type="password" id="inputPassword" className="form-control" placeholder={t('records.login.s1btn2txt')} required onChange={e => setPassword(e.target.value)}></input>
              <br />
              <div className="cf-turnstile"></div>
              <br />
              <button type="submit" className="btn btn-primary btn-block" >Sign in</button>
              <ToastContainer />
            </form>
            <br />
            <a href={process.env.REACT_APP_SIGNUP + '/recovery'} target="_blank" rel="noopener noreferrer">Forgot your password !</a>
          </div>
          <div className="col-4"></div>
        </div>
      </div>
    </div>
  );
}

Login.propTypes = {
  setToken: PropTypes.func.isRequired
}