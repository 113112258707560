import Login from "./Login"
import { useToken, useOtp } from './UseToken'
import Footer from "./Footer";
import Error from "./Error";

import { Link } from 'react-router-dom';
import { useState, useEffect } from "react";

import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

import QRCode from "react-qr-code";
import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { toPng } from 'html-to-image';
import download from 'downloadjs';

import Plantilla1 from './img/Plantilla1.svg';
import Plantilla2 from './img/Plantilla2.svg';

function textinfo(t) {
  return (
    <div >

      <main>
        <div class="d-flex flex-column flex-shrink-0 p-3 bg-light" style={{ "width": "200px" }}>
          <Link to="/" class="d-flex align-items-center mb-3 mb-md-0 me-md-auto link-dark text-decoration-none">
          </Link>
          <ul class="nav flex-column mb-auto">
            <li class="nav-item">
              <Link to="/app/dashboard" class="nav-link active" aria-current="page">
                {t('records.barside.dashboard')}
              </Link>
            </li>
            <li class="nav-item">
              <Link to="/app/create" class="nav-link active" aria-current="page">
                {t('records.barside.create')}
              </Link>
            </li>
            <li class="nav-item">
              <Link to="/app/records" class="nav-link active" aria-current="page">
                {t('records.barside.records')}
              </Link>
            </li>
            <li class="nav-item">
              <Link to="/app/delete" class="nav-link active" aria-current="page">
                {t('records.barside.delete')}
              </Link>
            </li>
          </ul>
        </div>

        <div className="container">
          <div className=" text-center justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom ">
            <h1 className="h2">{t('records.records.s1title')}</h1>
          </div>
          <div class="container">
            {t('records.records.s1desc')}
          </div>
        </div>
      </main>

      <Footer />
    </div>
  )
}

function RecordsReadUpdate() {
  const { t } = useTranslation();

  const [records, setRecords] = useState([]);
  const [urecord, setUrecord] = useState([]);
  const [requestSuccessful, setRequestSuccessful] = useState(false);
  const [user_id, setUser_id] = useState('');
  const { token, setToken } = useToken();
  const cache = useRef({});
  const qrRef = useRef(null);
  const templateRef = useRef(null);

  const [editableRecords, setEditableRecords] = useState([]);

  const downloadQRCode = () => {
    if (qrRef.current) {
      const svg = qrRef.current.innerHTML;
      const blob = new Blob([svg.toString()], { type: 'image/svg+xml' });
      const url = URL.createObjectURL(blob);
      let img = new Image();
      img.onload = () => {
        const borderWidth = 10;
        let canvas = document.createElement('canvas');
        canvas.width = img.width + borderWidth * 2;
        canvas.height = img.height + borderWidth * 2;
        const context = canvas.getContext('2d');
        context.fillStyle = '#FFFFFF';
        context.fillRect(0, 0, canvas.width, canvas.height);
        const x = borderWidth;
        const y = borderWidth;
        context.drawImage(img, x, y);
        const pngUrl = canvas.toDataURL('image/png');
        let downloadLink = document.createElement('a');
        downloadLink.href = pngUrl;
        downloadLink.download = 'my_qr_code.png';
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
      };

      img.src = url;
    }
  };

  const downloadTemplateBack = () => {
    if (templateRef.current) {
      toPng(templateRef.current, { backgroundColor: 'white' })
        .then((dataUrl) => {
          download(dataUrl, 'my_template.png');
        })
        .catch((error) => {
          console.error('Error al generar la imagen:', error);
        });
    }
  };

  const downloadTemplate = async (event) => {
    event.preventDefault();
    var errorResponse;

    try {
      const response = await fetch(process.env.REACT_APP_BACKEND + '/records/template', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,
        },
        body: JSON.stringify({ user_id: user_id }),
      });

      if (!response.ok) {
        errorResponse = await response.json();
        console.log(errorResponse);
        throw new Error('Error updating records');
      }

      const blob = await response.blob();
      const url = URL.createObjectURL(blob);
      window.open(url, '_blank');
      URL.revokeObjectURL(url);

    } catch (error) {
      console.log(error);
    }
  };


  useEffect(() => {
    if (!token) {
      return <Login setToken={setToken} />
    }

    const fetchData = async () => {
      try {
        const response = await fetch(process.env.REACT_APP_BACKEND + '/records/', {
          headers: {
            'Authorization': token,
          }
        });
        if (!response.ok) {
          return <Error />;
        }
        const data = await response.json();
        sessionStorage.setItem('records', JSON.stringify(data));
        setRecords(data);

      } catch (error) {
        console.error('Error fetching data:', error);
      }

    };
    fetchData();

  }, [token]);

  const handleInputChange = (e) => {
    setUser_id(e.target.value);
    setRequestSuccessful(false);
  };

  const handleRowClick = (userId) => {
    setUser_id(userId);
    handleSubmit(userId);
  };

  const [filterText, setFilterText] = useState('');

  const filteredRecords = records && records.info ? records.info.filter(register =>
    register.username.toLowerCase().includes(filterText.toLowerCase())
  ) : [];

  const handleSubmit = async (userId) => {
    if (cache.current[userId]) {
      console.log('Using cached data for user_id:', userId);
      setUrecord(cache.current[userId]);
      setEditableRecords(cache.current[userId]);
      setRequestSuccessful(true);
      return;
    }

    if (!userId || userId.trim() === '') {
      setRequestSuccessful(false);
      return;
    }

    try {
      const response = await fetch(process.env.REACT_APP_BACKEND + '/records/' + userId, {
        headers: {
          'Authorization': token,
        }
      });
      if (!response.ok) {
        toast.error('This user doesnt exist', {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        })
        return <Error />;
      }
      const data = await response.json();
      sessionStorage.setItem('record', JSON.stringify(data));
      setUrecord(data);
      setEditableRecords(data);
      setRequestSuccessful(true);

      cache.current[userId] = data;

    } catch (error) {
      console.error('Error fetching data:', error);
      setRequestSuccessful(false);
    }
  };

  const handleInputChangeUpdate = (section, field, value, index = null) => {
    if (index !== null) {
      setEditableRecords(prevRecords => {
        const updatedSection = [...prevRecords.info[section]];
        updatedSection[index] = {
          ...updatedSection[index],
          [field]: value
        };

        return {
          ...prevRecords,
          info: {
            ...prevRecords.info,
            [section]: updatedSection
          },
        };
      });
    } else if (index == null && value == null) {
      setEditableRecords(prevRecords => ({
        ...prevRecords,
        info: {
          ...prevRecords.info,
          [section]: field,
        },
      }));
    } else {
      setEditableRecords(prevRecords => ({
        ...prevRecords,
        info: {
          ...prevRecords.info,
          [section]: {
            ...prevRecords.info[section],
            [field]: value,
          },
        },
      }));
    }
  };

  const handleSubmitUpdate = async (event) => {
    event.preventDefault();
    editableRecords.records = editableRecords.info;
    var errorResponse;

    try {
      const response = await fetch(process.env.REACT_APP_BACKEND + '/records/' + user_id, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,
        },
        body: JSON.stringify(editableRecords),
      });

      if (!response.ok) {
        errorResponse = await response.json();
        console.log(errorResponse);
        throw new Error('Error updating records');
      }

      toast.success('Records Updated', {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      setTimeout(() => {
        window.location.reload();
      }, 2000);
    } catch (error) {
      toast.error('Records Not Updated: ' + errorResponse.error, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };

  if (!records || !records.info) {
    return <div>{textinfo(t)}</div>;
  }

  return (
    <div >
      <main>
        <div class="d-flex flex-column flex-shrink-0 p-3 bg-light" style={{ minWidth: "120px", maxWidth: "20%", borderRight: "2px solid #ddd"  }}>
          <Link to="/" class="d-flex align-items-center mb-3 mb-md-0 me-md-auto link-dark text-decoration-none">
          </Link>
          <ul class="nav flex-column mb-auto">
            <li class="nav-item">
              <Link to="/app/dashboard" class="nav-link active" aria-current="page">
                {t('records.barside.dashboard')}
              </Link>
            </li>
            <li class="nav-item">
              <Link to="/app/create" class="nav-link active" aria-current="page">
                {t('records.barside.create')}
              </Link>
            </li>
            <li class="nav-item">
              <Link to="/app/records" class="nav-link active" aria-current="page">
                {t('records.barside.records')}
              </Link>
            </li>
            <li class="nav-item">
              <Link to="/app/delete" class="nav-link active" aria-current="page">
                {t('records.barside.delete')}
              </Link>
            </li>
          </ul>
        </div>

        <div className="container" style={{ "overflow-y": "auto" }}>
          <div className=" text-center justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom ">
            <h1 className="h2">{t('records.records.s1title')}</h1>
          </div>


          <input
            type="text"
            placeholder={t('records.records.s2title')}
            value={filterText}
            onChange={(e) => setFilterText(e.target.value)}
            className="form-control mb-3"
          />
          <strong>{t('records.records.s1txt1')}: </strong>{records.properties.recordsLimit}  
          <strong> {t('records.records.s1txt2')}: </strong>{records.properties.recordsCurrent} <br />
          <div className="table-responsive">
            <table class="table table-responsive">
              <thead>
                <tr>
                  <th scope="col">{t('records.records.s1col1')}</th>
                  <th scope="col">{t('records.records.s1col2')}</th>
                  <th scope="col">{t('records.records.s1col3')}</th>
                </tr>
              </thead>
              <tbody>
                {filteredRecords.length > 0 ? (
                  filteredRecords.map((registers, index) => (
                    <tr className="table-light" key={index} onClick={() => handleRowClick(registers.user_id)}>
                      <td>{registers.username}</td>
                      <td>{registers.user_id}</td>
                      <td>{registers.active.toString()}</td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="3">No records available</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>

          {/* <br />
          <hr className="my-4" />
          <label class="form-label mt-4" for="inputValid">{t('records.records.s2title')}</label>
          <form onSubmit={handleSubmit}>
            <input
              class="form-control"
              type="text"
              style={{ width: '615px' }}
              value={user_id}
              onChange={handleInputChange}
            />
            <br />
            <button type="submit" className="btn btn-primary">{t('records.records.s2btn')}</button>
            <ToastContainer />
          </form> */}
          <br />
          <hr className="my-4" />
          {requestSuccessful && (
            <div>

              <ul class="nav nav-tabs" role="tablist">
                <li class="nav-item" role="presentation">
                  <a class="nav-link active" data-bs-toggle="tab" href="#home" aria-selected="true" role="tab">{t('records.records.s1txt3')}</a>
                </li>
                <li class="nav-item" role="presentation">
                  <a class="nav-link" data-bs-toggle="tab" href="#update" aria-selected="false" role="tab"
                    tabindex="-1">{t('records.records.s1txt4')}</a>
                </li>

              </ul>
              <div id="myTabContent" class="tab-content">
                <div class="tab-pane fade active show" id="home" role="tabpanel">


                  <div className=" text-center justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom ">
                    <h1 className="h2">{urecord.info['username']} - {t('json.sun.header')}</h1>
                  </div>

                  <div class="container" style={{ wordWrap: 'break-word', overflowWrap: 'break-word' }}>
                    <div class="row">

                      <div class="col-sm">
                        <div class="list-group">

                          <a href="#" class="list-group-item list-group-item-action flex-column align-items-start border-danger">
                            <div class="d-flex w-100 justify-content-between">
                              <h5 class="mb-1">{t('json.sci.title')}</h5>
                            </div>
                            <p class="mb-1">
                              <div class="col-sm">
                                <ul>
                                  <li>{t('json.spi.l1txt')} {urecord.info['Personal Information']['Full Name']}</li>
                                  <li>{t('json.spi.l2txt')} {urecord.info['Personal Information']['Date of Birth']}</li>
                                  <li>{t('json.spi.l4txt')} {urecord.info['Personal Information']['Blood Type']}</li>
                                  <li>{t('json.smh.l1txt')} {urecord.info['Medical History']['Known Allergies']}</li>
                                  <li>{t('json.smh.l3txt')} {urecord.info['Medical History']['Chronic Conditions']}</li>
                                  <li>{t('json.smii.l1txt')} {urecord.info['Medical Insurance Information']['Provider']}</li>
                                  <li>{t('json.smii.l2txt')} {urecord.info['Medical Insurance Information']['Policy Number']}</li>
                                  {urecord.info['Emergency Contact'].map((contact, index) => (
                                    <li>{contact.Name}, {contact.Relationship}, {contact.Phone}</li>
                                  ))}
                                </ul>
                              </div>
                            </p>
                          </a>

                          <hr className="my-4" />

                          <a href="#" class="list-group-item list-group-item-action flex-column align-items-start">
                            <div class="d-flex w-100 justify-content-between">
                              <h5 class="mb-1">{t('json.spi.title')}</h5>
                              <small class="text-muted">{t('json.spi.header')} {new Date(urecord.info.date).toLocaleDateString('en-CA')}</small>
                            </div>
                            <p class="mb-1">
                              <div class="col-sm">
                                <ul>
                                  <li>{t('json.spi.l1txt')} {urecord.info['Personal Information']['Full Name']}</li>
                                  <li>{t('json.spi.l2txt')} {urecord.info['Personal Information']['Date of Birth']}</li>
                                  <li>{t('json.spi.l3txt')} {urecord.info['Personal Information']['Gender']}</li>
                                  <li>{t('json.spi.l4txt')} {urecord.info['Personal Information']['Blood Type']}</li>
                                  <li>{t('json.spi.l5txt')} {urecord.info['Personal Information']['Address']}</li>
                                  <li>{t('json.spi.l6txt')} {urecord.info['Personal Information']['Email']}</li>
                                  <li>{t('json.spi.l7txt')} {urecord.info['Personal Information']['Phone Number']}</li>
                                  <li>{t('json.spi.l8txt')} {urecord.info['Personal Information']['Photo']}</li>
                                  <li>{t('json.spi.l9txt')} {urecord.info['Personal Information']['Ethnicity']}</li>
                                  <li>{t('json.spi.l10txt')} {urecord.info['Personal Information']['Preferred Language']}</li>
                                  <li>{t('json.spi.l11txt')} {urecord.info['Personal Information']['Nationality']}</li>
                                  <li>{t('json.spi.l12txt')} {urecord.info['Personal Information']['ID TYPE']}</li>
                                  <li>{t('json.spi.l13txt')} {urecord.info['Personal Information']['ID']}</li>
                                </ul>
                              </div>
                            </p>
                          </a>

                          <a href="#" class="list-group-item list-group-item-action flex-column align-items-start">
                            <div class="d-flex w-100 justify-content-between">
                              <h5 class="mb-1">{t('json.sec.title')}</h5>
                            </div>
                            <p class="mb-1">
                              <div class="col-sm">
                                <ul>
                                  {urecord.info['Emergency Contact'].map((contact, index) => (
                                    <li>{contact.Name}, {contact.Relationship}, {contact.Phone}</li>
                                  ))}
                                </ul>
                              </div>
                            </p>
                          </a>

                          <a href="#" class="list-group-item list-group-item-action flex-column align-items-start">
                            <div class="d-flex w-100 justify-content-between">
                              <h5 class="mb-1">{t('json.smh.title')}</h5>
                            </div>
                            <p class="mb-1">
                              <div class="col-sm">
                                <ul>
                                  <li>{t('json.smh.l1txt')} {urecord.info['Medical History']['Known Allergies']}</li>
                                  <li>{t('json.smh.l2txt')} {urecord.info['Medical History']['Past Surgeries']}</li>
                                  <li>{t('json.smh.l3txt')} {urecord.info['Medical History']['Chronic Conditions']}</li>
                                  <li>{t('json.smh.l4txt')} {urecord.info['Medical History']['Family Medical History']}</li>
                                  <li>{t('json.smh.l5txt')} {urecord.info['Medical History']['Vaccination History']}</li>
                                  <li>{t('json.smh.l6txt')} {urecord.info['Medical History']['Previous Hospitalizations']}</li>
                                </ul>
                              </div>
                            </p>
                          </a>


                          <a href="#" class="list-group-item list-group-item-action flex-column align-items-start">
                            <div class="d-flex w-100 justify-content-between">
                              <h5 class="mb-1">{t('json.scm.title')}</h5>
                            </div>
                            <p class="mb-1">
                              <div class="col-sm">
                                <ul>
                                  {urecord.info['Current Medications'].map((medications, index) => (
                                    <li>{medications.Medication}, {medications.Dosage}, {medications.Frequency}</li>
                                  ))}
                                </ul>
                              </div>
                            </p>
                          </a>

                          <a href="#" class="list-group-item list-group-item-action flex-column align-items-start">
                            <div class="d-flex w-100 justify-content-between">
                              <h5 class="mb-1">{t('json.scm2.title')}</h5>
                            </div>
                            <p class="mb-1">
                              <div class="col-sm">
                                <ul>
                                  <li>{urecord.info['Contraindicated Medications']}</li>
                                </ul>
                              </div>
                            </p>
                          </a>

                          <a href="#" class="list-group-item list-group-item-action flex-column align-items-start">
                            <div class="d-flex w-100 justify-content-between">
                              <h5 class="mb-1">{t('json.sppc.title')}</h5>
                            </div>
                            <p class="mb-1">
                              <div class="col-sm">
                                <ul>
                                  <li>{t('json.sppc.l1txt')} {urecord.info['Personal Physician Contact']['Name']}</li>
                                  <li>{t('json.sppc.l2txt')} {urecord.info['Personal Physician Contact']['Phone']}</li>
                                </ul>
                              </div>
                            </p>
                          </a>

                          <a href="#" class="list-group-item list-group-item-action flex-column align-items-start">
                            <div class="d-flex w-100 justify-content-between">
                              <h5 class="mb-1">{t('json.smii.title')}</h5>
                            </div>
                            <p class="mb-1">
                              <div class="col-sm">
                                <ul>
                                  <li>{t('json.smii.l1txt')} {urecord.info['Medical Insurance Information']['Provider']}</li>
                                  <li>{t('json.smii.l2txt')} {urecord.info['Medical Insurance Information']['Policy Number']}</li>
                                  <li>{t('json.smii.l3txt')}  {urecord.info['Medical Insurance Information']['Coverage']}</li>
                                  <li>{t('json.smii.l4txt')} {urecord.info['Medical Insurance Information']['Policy Details']}</li>
                                  <li>{t('json.smii.l5txt')} {urecord.info['Medical Insurance Information']['Insurance Contact']}</li>
                                </ul>
                              </div>
                            </p>
                          </a>

                          <a href="#" class="list-group-item list-group-item-action flex-column align-items-start">
                            <div class="d-flex w-100 justify-content-between">
                              <h5 class="mb-1">{t('json.sdn.title')}</h5>
                            </div>
                            <p class="mb-1">
                              <div class="col-sm">
                                <ul>
                                  <li>{t('json.sdn.l1txt')} {urecord.info['Doctor\'s Notes']['Date of Visit']}</li>
                                  <li>{t('json.sdn.l2txt')} {urecord.info['Doctor\'s Notes']['Reason for Visit']}</li>
                                  <li>{t('json.sdn.l3txt')} {urecord.info['Doctor\'s Notes']['Recommendations']}</li>
                                </ul>
                              </div>
                            </p>
                          </a>

                          <a href="#" class="list-group-item list-group-item-action flex-column align-items-start">
                            <div class="d-flex w-100 justify-content-between">
                              <h5 class="mb-1">{t('json.sod.title')}</h5>
                            </div>
                            <p class="mb-1">
                              <div class="col-sm">
                                <ul>
                                  <li>{t('json.sod.l1txt')} {urecord.info['Organ Donation']['Status']}</li>
                                  <li>{t('json.sod.l2txt')} {urecord.info['Organ Donation']['Additional information']}</li>
                                </ul>
                              </div>
                            </p>
                          </a>

                          <a href="#" class="list-group-item list-group-item-action flex-column align-items-start">
                            <div class="d-flex w-100 justify-content-between">
                              <h5 class="mb-1">{t('json.sad.title')}</h5>
                            </div>
                            <p class="mb-1">
                              <div class="col-sm">
                                <ul>
                                  <li>{urecord.info['Advance Directives']}</li>
                                </ul>
                              </div>
                            </p>
                          </a>

                          <a href="#" class="list-group-item list-group-item-action flex-column align-items-start">
                            <div class="d-flex w-100 justify-content-between">
                              <h5 class="mb-1">{t('json.san.title')}</h5>
                            </div>
                            <p class="mb-1">
                              <div class="col-sm">
                                <ul>
                                  <li>{urecord.info['Additional Notes']}</li>
                                </ul>
                              </div>
                            </p>
                          </a>

                        </div>
                      </div>

                      <div class="col-sm">
                        <div class="d-flex flex-column align-items-center">
                          <br />
                          <Link class="nav-link active" to={'/emergency/' + user_id} target="_blank" >
                            <div ref={qrRef}>
                              <QRCode
                                value={process.env.REACT_APP__HOME + '/emergency/' + user_id} size={256}
                              />
                            </div>
                          </Link >
                          <br />
                          <h5 class="mb-1">NIP: {urecord.info['nip']}</h5>
                          <br />
                          <div className="d-flex justify-content-center align-items-center">
                            <button type="button" className="btn btn-primary me-2" onClick={downloadQRCode}>{t('records.records.s3btn')}</button>
                            {/* <button type="button" className="btn btn-outline-secondary my-2 my-sm-0" onClick={downloadTemplate} >{t('records.records.s3btn2')}</button> */}
                          </div>

                          <em>
                            <br />
                            <div class="text-dark" >{t('records.records.s3txt')}</div>
                            <br />
                            <div class="text-secondary" >{t('records.records.s3txt1')}</div>
                            <br />
                          </em>
                          <strong>{t('records.records.s3foot')}</strong>
                          <hr className="my-4" />



                          <div className="d-flex justify-content-center align-items-center">
                            <button type="button" className="btn btn-outline-secondary my-2 my-sm-0" onClick={downloadTemplate} >{t('records.records.s3btn2')}</button>
                          </div>
                          <hr className="my-4" />
                          <div ref={templateRef} style={{ display: 'block' }}>
                            <div style={{ position: 'relative', display: 'inline-block', border: '2px solid black', padding: '5px', width: '100%' }}>
                              <img src={Plantilla2} alt="Plantilla2" style={{ width: '100%', height: 'auto' }} />
                              <strong
                                className="text-dark"
                                style={{
                                  position: 'absolute',
                                  top: '30%',
                                  left: '15%',
                                  fontSize: 'clamp(8px, 1.5vw, 12px)',
                                  whiteSpace: 'nowrap',
                                  overflow: 'hidden',
                                  textOverflow: 'ellipsis',
                                  maxWidth: '70%'
                                }}
                              >
                                {urecord.info['Personal Information']['Full Name']}
                              </strong>
                              <strong
                                className="text-dark"
                                style={{
                                  position: 'absolute',
                                  top: '85%',
                                  left: '68%',
                                  fontSize: 'clamp(12px, 2vw, 18px)',
                                  whiteSpace: 'nowrap',
                                  overflow: 'hidden',
                                  textOverflow: 'ellipsis',
                                  maxWidth: '30%'
                                }}
                              >
                                NIP: {urecord.info['nip']}
                              </strong>
                              <QRCode
                                value={process.env.REACT_APP__HOME + '/emergency/' + user_id}
                                size={256}
                                style={{
                                  position: 'absolute',
                                  top: '50%',
                                  left: '60%',
                                  transform: 'translate(-5%, -45%)',
                                  width: '30%',
                                  maxWidth: '100%',
                                }}
                              />
                            </div>

                            <div style={{ position: 'relative', display: 'inline-block', border: '2px solid black', padding: '5px', width: '100%', marginTop: '20px' }}>
                              <img src={Plantilla1} alt="Plantilla1" style={{ width: '100%', height: 'auto' }} />
                            </div>
                          </div>


                        </div>
                      </div>
                    </div>
                  </div>
                </div>



                <div class="tab-pane fade" id="update" role="tabpanel">


                  <div className="container">
                    <form onSubmit={handleSubmitUpdate}>
                      <button type="submit" className="btn btn-primary">{t('records.update.s2btn')}</button>
                      <br />
                      <br />

                      <ul>
                        <li><a href="#spi">{t('json.spi.title')}</a></li>
                        <li><a href="#sec">{t('json.sec.title')}</a></li>
                        <li><a href="#smh">{t('json.smh.title')}</a></li>
                        <li><a href="#smii">{t('json.smii.title')}</a></li>
                      </ul>
                      <br />

                      <h5>{t('json.sun.title')}</h5>
                      <input className="form-control" value={editableRecords.info['username'] || ''} onChange={(e) => handleInputChangeUpdate('username', e.target.value)} /><br />

                      <h5>NIP</h5>
                      <input className="form-control" value={editableRecords.info['nip'] || ''} onChange={(e) => handleInputChangeUpdate('nip', e.target.value)} /><br />

                      <h5 id="spi">{t('json.spi.title')}</h5>
                      {t('json.spi.l1txt')}<input className="form-control" type="text" value={editableRecords.info['Personal Information']['Full Name']} onChange={(e) => handleInputChangeUpdate('Personal Information', 'Full Name', e.target.value)} /><br />
                      {t('json.spi.l2txt')}<input className="form-control" type="text" value={editableRecords.info['Personal Information']['Date of Birth']} onChange={(e) => handleInputChangeUpdate('Personal Information', 'Date of Birth', e.target.value)} /><br />
                      {t('json.spi.l3txt')}<input className="form-control" type="text" value={editableRecords.info['Personal Information']['Gender']} onChange={(e) => handleInputChangeUpdate('Personal Information', 'Gender', e.target.value)} /><br />
                      {t('json.spi.l4txt')}<input className="form-control" type="text" value={editableRecords.info['Personal Information']['Blood Type']} onChange={(e) => handleInputChangeUpdate('Personal Information', 'Blood Type', e.target.value)} /><br />
                      {t('json.spi.l5txt')}<input className="form-control" type="text" value={editableRecords.info['Personal Information']['Address']} onChange={(e) => handleInputChangeUpdate('Personal Information', 'Address', e.target.value)} /><br />
                      {t('json.spi.l6txt')}<input className="form-control" type="email" value={editableRecords.info['Personal Information']['Email']} onChange={(e) => handleInputChangeUpdate('Personal Information', 'Email', e.target.value)} /><br />
                      {t('json.spi.l7txt')}<input className="form-control" type="text" value={editableRecords.info['Personal Information']['Phone Number']} onChange={(e) => handleInputChangeUpdate('Personal Information', 'Phone Number', e.target.value)} /><br />
                      {t('json.spi.l8txt')}<input className="form-control" type="text" value={editableRecords.info['Personal Information']['Photo']} onChange={(e) => handleInputChangeUpdate('Personal Information', 'Photo', e.target.value)} /><br />
                      {t('json.spi.l9txt')}<input className="form-control" type="text" value={editableRecords.info['Personal Information']['Ethnicity']} onChange={(e) => handleInputChangeUpdate('Personal Information', 'Ethnicity', e.target.value)} /><br />
                      {t('json.spi.l10txt')}<input className="form-control" type="text" value={editableRecords.info['Personal Information']['Preferred Language']} onChange={(e) => handleInputChangeUpdate('Personal Information', 'Preferred Language', e.target.value)} /><br />
                      {t('json.spi.l11txt')}<input className="form-control" type="text" value={editableRecords.info['Personal Information']['Nationality']} onChange={(e) => handleInputChangeUpdate('Personal Information', 'Nationality', e.target.value)} /><br />
                      {t('json.spi.l12txt')}<input className="form-control" type="text" value={editableRecords.info['Personal Information']['ID TYPE']} onChange={(e) => handleInputChangeUpdate('Personal Information', 'ID TYPE', e.target.value)} /><br />
                      {t('json.spi.l13txt')}<input className="form-control" type="text" value={editableRecords.info['Personal Information']['ID']} onChange={(e) => handleInputChangeUpdate('Personal Information', 'ID', e.target.value)} /><br />

                      <h5 id="sec">{t('json.sec.title')}</h5>
                      {editableRecords.info['Emergency Contact'].map((contact, index) => (
                        <div key={index}>
                          <strong>{t('json.sec.header')} {index + 1}</strong> <br />
                          {t('json.sec.l1txt')}<input className="form-control" type="text" value={contact.Name} onChange={(e) => handleInputChangeUpdate('Emergency Contact', 'Name', e.target.value, index)} /><br />
                          {t('json.sec.l2txt')}<input className="form-control" type="text" value={contact.Relationship} onChange={(e) => handleInputChangeUpdate('Emergency Contact', 'Relationship', e.target.value, index)} /><br />
                          {t('json.sec.l3txt')}<input className="form-control" type="tel" value={contact.Phone} onChange={(e) => handleInputChangeUpdate('Emergency Contact', 'Phone', e.target.value, index)} /><br />
                        </div>
                      ))}

                      <h5 id="smh">{t('json.smh.title')}</h5>
                      {t('json.smh.l1txt')}<input className="form-control" type="text" value={editableRecords.info['Medical History']['Known Allergies'] || ''} onChange={(e) => handleInputChangeUpdate('Medical History', 'Known Allergies', e.target.value)} /><br />
                      {t('json.smh.l2txt')}<input className="form-control" type="text" value={editableRecords.info['Medical History']['Past Surgeries'] || ''} onChange={(e) => handleInputChangeUpdate('Medical History', 'Past Surgeries', e.target.value)} /><br />
                      {t('json.smh.l3txt')}<input className="form-control" type="text" value={editableRecords.info['Medical History']['Chronic Conditions'] || ''} onChange={(e) => handleInputChangeUpdate('Medical History', 'Chronic Conditions', e.target.value)} /><br />
                      {t('json.smh.l4txt')}<input className="form-control" type="text" value={editableRecords.info['Medical History']['Family Medical History'] || ''} onChange={(e) => handleInputChangeUpdate('Medical History', 'Family Medical History', e.target.value)} /><br />
                      {t('json.smh.l5txt')}<input className="form-control" type="text" value={editableRecords.info['Medical History']['Vaccination History'] || ''} onChange={(e) => handleInputChangeUpdate('Medical History', 'Vaccination History', e.target.value)} /><br />
                      {t('json.smh.l6txt')}<input className="form-control" type="text" value={editableRecords.info['Medical History']['Previous Hospitalizations'] || ''} onChange={(e) => handleInputChangeUpdate('Medical History', 'Previous Hospitalizations', e.target.value)} /><br />

                      <h5>{t('json.scm.title')}</h5>
                      {editableRecords.info['Current Medications'].map((medication, index) => (
                        <div key={index}>
                          <strong>{t('json.scm.header')} {index + 1}</strong> <br />
                          {t('json.scm.l1txt')}<input className="form-control" type="text" value={medication.Medication || ''} onChange={(e) => handleInputChangeUpdate('Current Medications', 'Medication', e.target.value, index)} /><br />
                          {t('json.scm.l2txt')}<input className="form-control" type="text" value={medication.Dosage || ''} onChange={(e) => handleInputChangeUpdate('Current Medications', 'Dosage', e.target.value, index)} /><br />
                          {t('json.scm.l3txt')}<input className="form-control" type="text" value={medication.Frequency || ''} onChange={(e) => handleInputChangeUpdate('Current Medications', 'Frequency', e.target.value, index)} /><br />
                        </div>
                      ))}

                      <h5>{t('json.scm2.title')}</h5>
                      <input className="form-control" value={editableRecords.info['Contraindicated Medications'] || ''} onChange={(e) => handleInputChangeUpdate('Contraindicated Medications', e.target.value)} /><br />

                      <h5>{t('json.sppc.title')}</h5>
                      {t('json.sppc.l1txt')}<input className="form-control" type="text" value={editableRecords.info['Personal Physician Contact']['Name'] || ''} onChange={(e) => handleInputChangeUpdate('Personal Physician Contact', 'Name', e.target.value)} /><br />
                      {t('json.sppc.l2txt')}<input className="form-control" type="text" value={editableRecords.info['Personal Physician Contact']['Phone'] || 'N/A'} onChange={(e) => handleInputChangeUpdate('Personal Physician Contact', 'Phone', e.target.value)} /><br />

                      <h5 id="smii">{t('json.smii.title')}</h5>
                      {t('json.smii.l1txt')}<input className="form-control" type="text" value={editableRecords.info['Medical Insurance Information']['Provider'] || ''} onChange={(e) => handleInputChangeUpdate('Medical Insurance Information', 'Provider', e.target.value)} /><br />
                      {t('json.smii.l2txt')}<input className="form-control" type="text" value={editableRecords.info['Medical Insurance Information']['Policy Number'] || ''} onChange={(e) => handleInputChangeUpdate('Medical Insurance Information', 'Policy Number', e.target.value)} /><br />
                      {t('json.smii.l3txt')}<input className="form-control" type="text" value={editableRecords.info['Medical Insurance Information']['Coverage'] || ''} onChange={(e) => handleInputChangeUpdate('Medical Insurance Information', 'Coverage', e.target.value)} /><br />
                      {t('json.smii.l4txt')}<input className="form-control" type="text" value={editableRecords.info['Medical Insurance Information']['Policy Details'] || ''} onChange={(e) => handleInputChangeUpdate('Medical Insurance Information', 'Policy Details', e.target.value)} /><br />
                      {t('json.smii.l5txt')}<input className="form-control" type="text" value={editableRecords.info['Medical Insurance Information']['Insurance Contact'] || ''} onChange={(e) => handleInputChangeUpdate('Medical Insurance Information', 'Insurance Contact', e.target.value)} /><br />

                      <h5>{t('json.sdn.title')}</h5>
                      {t('json.sdn.l1txt')}<input className="form-control" type="text" value={editableRecords.info['Doctor\'s Notes']['Date of Visit'] || ''} onChange={(e) => handleInputChangeUpdate('Doctor\'s Notes', 'Date of Visit', e.target.value)} /><br />
                      {t('json.sdn.l2txt')}<input className="form-control" type="text" value={editableRecords.info['Doctor\'s Notes']['Reason for Visit'] || ''} onChange={(e) => handleInputChangeUpdate('Doctor\'s Notes', 'Reason for Visit', e.target.value)} /><br />
                      {t('json.sdn.l3txt')}<input className="form-control" type="text" value={editableRecords.info['Doctor\'s Notes']['Recommendations'] || ''} onChange={(e) => handleInputChangeUpdate('Doctor\'s Notes', 'Recommendations', e.target.value)} /><br />

                      <h5>{t('json.sod.title')}</h5>
                      {t('json.sod.l1txt')}<input className="form-control" type="text" value={editableRecords.info['Organ Donation']['Status'] || ''} onChange={(e) => handleInputChangeUpdate('Organ Donation', 'Status', e.target.value)} /><br />
                      {t('json.sod.l2txt')}<input className="form-control" type="text" value={editableRecords.info['Organ Donation']['Additional information'] || 'N/A'} onChange={(e) => handleInputChangeUpdate('Organ Donation', 'Additional information', e.target.value)} /><br />

                      <h5>{t('json.sad.title')}</h5>
                      <input className="form-control" type="text" value={editableRecords.info['Advance Directives'] || ''} onChange={(e) => handleInputChangeUpdate('Advance Directives', e.target.value)} /><br />

                      <h5>{t('json.san.title')}</h5>
                      <input className="form-control" type="text" value={editableRecords.info['Additional Notes'] || ''} onChange={(e) => handleInputChangeUpdate('Additional Notes', e.target.value)} /><br />

                      <br />
                      <br />
                      <button type="submit" className="btn btn-primary">{t('records.update.s2btn')}</button>
                      <ToastContainer />
                    </form>
                  </div>










                </div>
              </div>


            </div>
          )}

        </div>


      </main>
      <Footer />
    </div>
  );
}

export default RecordsReadUpdate;
